/* global.css */
@tailwind base;

@layer base {
  img {
    @apply inline-block;
  }
}

@tailwind components;
@tailwind utilities;

.mainbody {
  font-size: 10px !important;
}

body {
  font-size: 13px !important;
  font-family: system-ui;
}

.side-nav-transparent {
  background-image: url(./assets/img/auth-side-bg.jpg);
  color: #fff;
}

.side-nav-transparent .side-nav-content .menu-collapse .menu-collapse-item {
  color: #fff !important;
  height: 28px !important;
  overflow-y: hidden;
}

.side-nav-transparent .side-nav-content .menu-item-transparent {
  color: #fff !important;
}

.menu-item.menu-item-transparent.menu-item-hoverable:hover {
  color: #fff !important;
  overflow-y: hidden;
}

.select .select__control {
  min-height: 0px !important;
}

.input-md {
  height: 38px !important;
}

.sp-input {
  height: 35px !important;
  font-size: x-small !important;
}

.side-nav-transparent
  .side-nav-content
  .menu-transparent
  .activecls
  .menu-item-transparent {
  color: #000 !important;
}

.side-nav-transparent
  .side-nav-content
  .menu-transparent
  .menu-item-transparent {
  height: 25px !important;
}

.side-nav-transparent .side-nav-content .menu-transparent .menu-collapse {
  min-height: 26px !important;
}

.side-nav-transparent .side-nav-content .menu-transparent .activecls {
  margin-bottom: 0px;
}

.dashboardtopsection {
  margin-bottom: -10px;
}

.filterarea .card-body {
  padding: 0px 10px 0px 10px !important;
}

.filterarea .filter-search p {
  margin-top: 7px !important;
}

.datatablearea {
  margin-top: 10px;
}

.datatablearea .card-body {
  padding: 6px 10px 6px 10px !important;
}

.datatablearea .card-body h3,
h4 {
  font-size: 13px;
}

.datatablearea .card-body button {
  font-size: 13px;
}

.datatablearea table {
  color: #000;
}

.datatablearea tfoot {
  font-weight: 550;
}

.datatablearea .table-default thead tr {
  font-size: 8px !important;
}

.datatablearea .table-default thead tr th {
  font-size: 10px !important;
  text-align: center;
}

.datatablearea .table-default tbody tr td {
  padding: 2px 5px !important;
  text-align: center;
}

.datatablearea .table-default tbody tr td button {
  font-size: 10px !important;
}

.datatablearea .editbtn {
  margin-left: 12px;
  height: 30px;
}

.datatablearea .editbtn.opacity-50 {
  opacity: 0.3;
}

.datatablearea .editbtn i {
  font-size: 15px;
  color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.datatablearea .deletebtn {
  margin-left: 12px;
  height: 28px;
}

.datatablearea .deletebtn.opacity-50 {
  opacity: 0.3;
}

.datatablearea .deletebtn i {
  font-size: 15px;
  color: #ff0000;
}

.datatablearea .dataexportbtn {
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  color: #fff;
}

.datatablearea .dataexportbtn .btntext {
  font-size: 11px;
}

.datatablearea thead {
  z-index: 1 !important;
}

.datatablearea .textredcls {
  color: #ff0000 !important;
}

.datatablearea .topiclistarea {
  text-align: left;
  padding: 5px;
}

.customdatatable {
  margin-top: 0px !important;
}

.datatablearea .tableaction {
  display: flex;
  justify-content: center;
}

.datatablearea h3,
h4,
h5,
h6 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.datatablearea .card-body {
  margin: 5px !important;
}

.pagenamearea h3,
h4,
h5,
h6 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.pagination .pagination-pager {
  font-size: 12px !important;
}

.menu-item-transparent .text-2xl {
  font-size: 20px !important;
}

.menu-collapse-item-transparent .text-2xl {
  font-size: 20px !important;
}

.headerclass {
  background-color: rgb(79, 70, 229);
}

.headerclass .header-action-item {
  color: #fff;
}

.headerclass .avatar-img {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 0.1px;
}

.dashboardcardarea .card-body .font-semibold {
  font-size: 13px;
}

.dashboardcardarea .card-body .font-bold {
  font-size: 16px;
}

.dashboardstbtn {
  font-size: 12px !important;
}

.innerpagewrapper {
  padding: 10px 10px 10px 10px !important;
}

.errorclass {
  margin-top: 60px !important;
}

.searchbtn {
  color: #fff;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
  /* margin-top: 5px; */
}

.searchbtn:hover {
  color: #fff;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}

.shortwidthbtn {
  width: 50%;
}

.shortwidthbtn:hover {
  background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}

.fonticon {
  margin-right: 4px;
  font-size: 13px;
}

.modalpopup {
  height: 570px !important;
  padding: 0px;
}

.modalpopup .drawer-header {
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px 5px 15px;
  font-size: 12px;
}

.modalpopup .drawer-header h4 {
  color: #fff;
  font-size: 13px;
}

.modalpopup .drawer-header .close-btn {
  color: #fff;
}

.modalpopup .drawer-header .close-btn:hover {
  color: #fff;
}

.modalpopup .drawer-footer {
  background-color: #80808042;
  border-radius: 0px 0px 5px 5px;
  padding: 10px 15px 10px 15px;
}

.modalpopup .drawer-footer .cancelbtn {
  background-color: #ff0000;
  color: #fff;
}

.modalpopup .cancelbtn {
  background-color: #ff0000;
  color: #fff;
}

.modalpopup .drawer-footer .cancelbtn:hover {
  background-color: #fb6969;
}

.modalpopup .cancelbtn:hover {
  background-color: #fb6969;
}

.modalpopup .drawer-body {
  padding: 10px 15px 10px 15px;
}

.modalpopup .drawer-body .form-item {
  margin-bottom: 10px !important;
}

.modalpopup .card-body {
  padding: 0px 10px 0px 10px !important;
}

.breadcrumbarea {
  z-index: 9 !important;
}

.menulist {
  padding: 8px 0px 0px 0px;
}

.studentlist {
  height: 410px;
  padding: 0px 0px 0px 0px;
}

.starclass {
  color: red;
}

.customsearch .card-border {
  padding: 10px;
  border-radius: 8px;
}

.select__menu {
  align-items: left;
  text-align: left;
  justify-content: left;
}

.customsearch .card-body {
  padding: 10px 10px 10px 10px !important;
}

.customsearch .form-container .form-item {
  margin-bottom: 5px !important;
}

.customsearch .searchbtn:hover {
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.notesection {
  font-size: 14px;
  font-weight: 600;
  color: brown;
}

.datatablearea .dataexportbtn:hover {
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.form-container .formarea .resetbtn:hover {
  background-color: #fb6969;
}

.form-container .formarea .cancelbtn:hover {
  background-color: #fb6969;
}

.studentshowbtn {
  text-align: center;
}

.batchentry-progressloader {
  height: 12px;
  margin-top: 10px;
}

.modalloaderarea {
  height: 22px;
}

.spinnerloader {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-bottom-color: rgb(79, 70, 229);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderarea {
  text-align: center;
  height: 30px;
  margin-bottom: 10px;
}

.studentlist .card-body {
  padding: 1px !important;
}

.presentsection {
  height: 50px;
  /* margin-left: 15px; */
}

.studentlist .cardtoparea {
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-radius: 5px 5px 0px 0px;
}

.studentlist .cardtoparea .flexboxselectarea {
  display: flex;
  justify-content: space-between;
}

.studentlist .stdlistarea {
  padding: 0px 5px 0px 15px;
  height: 310px;
  overflow-y: auto;
}

.batchWiseAttendanceCard .facultysection {
  padding: 0px 20px !important;
}

.starcls {
  color: #ff0000 !important;
}

.userselectarea .msgcls {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 100px;
}

.userselectarea .user-list-section {
  max-height: 440px;
  overflow-y: scroll;
}

.userselectarea .show-progress {
  height: 12px;
  padding: 6px;
}

.custominputcls {
  width: 203px;
}

.custominputcls input {
  height: 35px;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  width: 100%;
  padding: 10px 10px;
}

.custominputcls input:focus {
  outline: none !important;
  border: 2px solid #4f46e5;
}

.customfooterbtn {
  position: absolute;
  bottom: 0px;
}

.singlebtnwidth {
  display: flex;
  justify-content: center;
}

.paperbtnwidth {
  width: 15% !important;
}

.react-tagsinput {
  border-radius: 5px;
}

.tabcardarea .card-body {
  padding: 0px 0px 0px 10px !important;
}

.customcardarea .card-body {
  padding: 10px 10px 10px 10px !important;
}

.customcardarea .form-item {
  margin-bottom: 10px;
}

.mentorsearcharea {
  padding: 10px 10px 10px 10px !important;
}

.mentorsearcharea .card-body {
  padding: 10px 0px 0px 0px !important;
}

.mentorsearcharea .form-item {
  margin-bottom: 10px !important;
}

.departmentBelowSpecifiedAttendance {
  padding: 10px 10px 5px 10px;
}

.departmentBelowSpecifiedAttendance .card-body {
  padding: 0px 0px 5px;
}

.departmentBelowSpecifiedAttendance .form-item {
  margin-bottom: 10px;
}

.batchWiseAttendanceSpecifiedForm {
  padding: 10px 10px 5px 10px;
}

.batchWiseAttendanceSpecifiedForm .card-body {
  padding: 10px;
}

.batchWiseAttendanceSpecifiedForm .form-item {
  margin-bottom: 10px;
}

.searchcardcommoncls {
  padding: 10px 10px 5px 10px;
}

.searchcardcommoncls .card-body {
  padding: 0px;
}

.searchcardcommoncls .form-item {
  margin-bottom: 10px;
}

.mentorscheduesearch .card-body {
  padding: 0px !important;
}

.mentorreporttableheader {
  text-align: center !important;
}

.dropoutStudentListFilterFormcard {
  padding: 0px;
}

.dropoutStudentListFilterFormcard .card-body {
  padding: 10px;
}

.dropoutStudentListFilterFormcard .form-item {
  width: 100%;
  margin-bottom: 10px;
}

.studentmentoringsearch .card-body {
  padding: 10px 10px 10px 10px !important;
}

.actionbtn {
  background: #4f46e5;
  color: #fff;
}

.actionbtn:hover {
  background: #4f46e5 !important;
  color: #fff;
}

.studentmentoringattendancesearch .form-item .form-label {
  font-weight: 400;
  margin-bottom: 0px;
}

.studentmentoringattendancesearch .card-body {
  padding: 10px 10px 10px 10px !important;
}

.studentmentoringattendancesearch .form-item {
  margin-bottom: 5px !important;
}

.mentoringMarksTable .mentorName {
  background: #4f46e5;
  color: #fff;
  text-align: center;
}

.mentoringMarksTable .mentorName span {
  margin: 10px;
}

/* .batchWiseAttendanceCard .card-body {
  padding: 10px 10px 10px 10px !important;
} */

.batchWiseAttendanceCard .form-item {
  margin-bottom: 5px;
}

.classWiseAttendanceLockCard .card-body {
  padding: 10px 10px 10px 10px !important;
}

.classWiseAttendanceLockCard .form-item {
  margin-bottom: 10px !important;
}

.classWiseAttendanceLockCard .headercheckbox:checked {
  background-color: transparent;
  border: 1px solid;
}

.classWiseAttendanceLockCard .deletebtn {
  margin-left: 0px !important;
}

.footercls {
  background-color: #c9c9c9;
}

.portalarea {
  background: linear-gradient(0deg, #00000075, #00000075),
    url(./assets/img/brainware-university.webp);
  background-size: cover;
}

.portalarea .card {
  padding: 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.portalarea .card-body {
  padding: 15px;
}

.portalarea .portalicon svg {
  display: block;
  margin: auto;
  font-size: 60px;
  color: #4f46e5;
}

.portalarea .portaltitle {
  text-align: center;
  font-family: Serif !important;
  font-size: 22px;
  font-weight: 600;
}

.portalarea .card-shadow {
  width: 170px;
  /* opacity: 0.8; */
}

.mentordashboard table,
tr,
td,
th {
  border: 1px solid black;
}

.mentordashboard table {
  width: 100%;
}

.mentordashboard td {
  text-align: center;
  vertical-align: middle;
}

.mentordashboard .tablerow {
  background-color: #4f46e5;
  color: #fff;
}

.mentordashboard .tablerow th {
  font-size: 11px;
  text-transform: uppercase;
}

.mentordashboard .tablefooter {
  background-color: #4f46e5;
  color: #fff;
}

.mentordashboard .tablefooter td {
  font-size: 11px;
  text-transform: uppercase;
}

.mentordashboard .linechartMarks canvas {
  height: 150px !important;
  width: 100%;
  margin-left: 15px;
}

.mentordashboard .linechartAttendance canvas {
  height: 150px !important;
  width: 100%;
  margin-left: 15px;
}

.studentMentoringtable {
  width: 100%;
  overflow-x: scroll;
}

.studentMentoringtable table,
tr,
td,
th {
  border: 1px solid black;
}

.studentMentoringtable table {
  width: 100%;
  overflow: scroll;
}

.studentMentoringtable th {
  padding: 5px;
}

.studentMentoringtable td {
  text-align: center;
  vertical-align: middle;
}

.studentMentoringtable .tablerow {
  background-color: #4f46e5;
  color: #fff;
}

.studentMentoringtable .tablerow th {
  font-size: 11px;
  text-transform: uppercase;
}

.studentMentoringtable .tablefooter {
  background-color: #4f46e5;
  color: #fff;
}

.studentMentoringtable .tablefooter td {
  font-size: 11px;
  text-transform: uppercase;
}

.studentMentoringtable .linechartstatus canvas {
  height: 50px !important;
}

.teacherDailyActivityReportArea .form-item .form-label {
  font-weight: 400;
  margin-bottom: 0px;
}

.teacherDailyActivityReportArea .card-body {
  padding: 10px 10px 10px 10px !important;
}

.teacherDailyActivityReportArea .form-item {
  margin-bottom: 5px !important;
}

.teacherDailyActivityTable {
  width: 100%;
  overflow-x: scroll;
}

.teacherDailyActivityTable table,
tr,
td,
th {
  border: 1px solid black;
}

.teacherDailyActivityTable table {
  width: 100%;
  overflow: scroll;
}

.teacherDailyActivityTable td {
  text-align: center;
  vertical-align: middle;
}

.teacherDailyActivityTable .tablerow {
  background-color: #4f46e5;
  color: #fff;
}

.teacherDailyActivityTable .tablerow th {
  font-size: 11px;
  text-transform: uppercase;
}

.teacherDailyActivityTable .tablefooter {
  background-color: #4f46e5;
  color: #fff;
}

.teacherDailyActivityTable .tablefooter td {
  font-size: 11px;
  text-transform: uppercase;
}

.mentorstatus.N {
  color: #ff0000;
}

.mentorstatus.Y {
  color: #11c700;
}

/* dashboard class */
/* .commondashboardtable {
    width: 100%;
    height: 100%;
    overflow-x: auto;
} */

.commondashboardtable table,
tr,
td,
th {
  border: 1px solid black;
}

.commondashboardtable table {
  width: 100%;
  overflow: scroll;
  box-shadow: 5px 5px 5px #bdbdbd;
}

.commondashboardtable td {
  text-align: center;
  vertical-align: middle;
}

.commondashboardtable .tablerow {
  /* background-color: #4f46e5; */
  /* color: #fff; */
}

.commondashboardtable .tablerow th {
  font-size: 11px;
  text-transform: uppercase;
  background: #4f46e5;
}

.commondashboardtable .tablefooter {
  /* background-color: #4f46e5; */
  /* color: #fff; */
}

.commondashboardtable .tablefooter td {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #e5e7eb;
}

.commondashboardtable .linechartstatus canvas {
  height: 50px !important;
}

.commondashboardtable .card-body {
  padding: 2px !important;
  overflow-x: auto;
}

.commondashboardtable table tr,
th,
td {
  border: 1px solid #9f9f9f !important;
}

.commondashboardtable table tr {
  border: 1px solid #9f9f9f !important;
  background-color: #047edf;
}

.commondashboardtable table .tabledata:hover {
  border: 1px solid #c9c9c9 !important;
  background-color: #c9c9c9;
  color: #000;
}

.commondashboardcard .card-body {
  padding: 5px !important;
  overflow-x: auto;
}

.commondashboardchart .card-body {
  padding: 5px !important;
  overflow-x: auto;
}

.commonDashboardArea {
  font-size: 12px;
}

.commonDashboardArea span {
  font-size: 12px;
}

.commonDashboardArea .commoncardname {
  columns: #000;
  font-weight: 600;
  font-family: Serif;
  font-size: 13px;
}

.dashboardcardlistarea .cardcursor {
  cursor: pointer;
  text-decoration: underline;
  color: #4f46e5;
  font-size: 14px;
  font-weight: 550;
}

.commondashboardlist .card-body {
  padding: 2px !important;
  /* overflow-y: scroll; */
  /* height: 150px; */
}

.dashboardcardlistarea ul {
  overflow-y: scroll;
  height: 100px;
}

.dashboardcardlistarea ul::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  color: #4f46e5;
}

.commondashboardtable {
  box-shadow: 5px 5px 5px #bdbdbd;
  border-radius: 10px;
}

.commondashboardlist {
  box-shadow: 5px 5px 5px #bdbdbd;
  border-radius: 10px;
}

.commondashboardchart {
  box-shadow: 5px 5px 5px #bdbdbd;
  border-radius: 10px;
}

.commondashboardcard {
  box-shadow: 5px 5px 5px #bdbdbd;
  border-radius: 10px;
}

.batchentryfilter .card-body {
  padding: 5px 5px 5px 5px;
}

.batchentryfilter .form-item {
  margin-bottom: 10px !important;
}

.assignstudentbatch .card-body {
  padding: 10px 10px 10px 10px;
}

.teacherleavingentryform .card-border {
  border-radius: 8px;
}

.teacherleavingentryform .card-border {
  padding: 8px 8px 0px 5px;
}

.teacherleavingentryform .form-item {
  margin-bottom: 15px;
}

.grandtotalrow {
  background-color: #d1d1d1;
}

.usernameselect {
  overflow-y: auto;
  height: 100px;
}

.academicsmasterrole.card-body {
  padding: 0px;
}

.portalnamecls {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: Serif;
  color: #fff;
}

.tabview .tab-nav-active {
  background-color: #7169eb !important;
  color: #fff !important;
}

.tabview .tab-nav-pill {
  background-color: #d1d1d1;
  color: #3b3a3a;
}

.tabview .tab-nav-pill:hover {
  background-color: #00a189;
  color: #fff;
}

.tabview .card-body {
  padding: 4px !important;
}

.tabview .form-item {
  margin-bottom: 5px !important;
}

.teacher-activity-tab .right-side .main-card {
  height: 500px;
  overflow-y: scroll;
}

.teacher-activity-tab .right-side .content-card {
  height: 485px;
  overflow-y: scroll;
}

.teacher-activity-tab .left-side .card {
  height: 500px;
  overflow-y: scroll;
}

.teacher-activity-tab .left-side .tab-card {
  height: 485px;
  overflow-y: scroll;
}
.student-activity-tab .left-side .tab-card,
.student-activity-tab .right-side .content-card {
  height: 450px;
}

.teacher-activity-tab .left-side .card-body {
  padding: 4px;
}

.teacher-activity-tab .left-side .card-body .tab-list .tab-nav {
  margin-bottom: 3px !important;
}

.teacher-activity-tab .left-side .card-body .tab-list .tab-nav-pill {
  padding: 4px !important;
  justify-content: left;
  margin-right: 0px !important;
  font-size: 12px;
  background: #e7e7e7;
}

.teacher-activity-tab .left-side .card-body .tab-list .tab-nav-pill:hover {
  background: #00a189;
  color: #fff;
}

.teacher-activity-tab .left-side .card-body .tab-list .tab-nav .fa-arrow-right {
  color: #2b2bff !important;
}

.teacher-activity-tab .tab-list {
  display: inline !important;
}

.teacher-activity-tab .tab-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
}

.teacher-activity-tab .left-side .card-title {
  display: flex;
  justify-content: center;
  font-size: 11px;
  margin-bottom: 10px;
  font-weight: 600;
  text-decoration: underline;
}

.tabsavebtn {
  text-align: center;
  margin-top: 15px;
}

#react-select-2-listbox {
  float: left !important;
  text-align: left !important;
}

.progress-loader {
  height: 8px !important;
}

.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.UniversityAttendanceForm .card-body {
  padding: 10px;
}

.UniversityAttendanceForm .form-item {
  margin-bottom: 10px;
}

.spinnerarea {
  margin-top: 50px;
}

.side-nav-content {
  height: calc(85vh - 4rem) !important;
}

.app-layout-simple .logo img {
  width: 100px;
}

.app-layout-simple .secondary-header-light::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4f46e5;
}

.app-layout-simple .secondary-header-light::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* ===== Scrollbar CSS ===== */
* {
  /* scrollbar-width: thin; */
  /* scrollbar-color: #4f46e5 #ffffff;/ */
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4f46e5;
  -webkit-border-radius: 10px;
}

.sidedescarea p {
  font-size: 15px;
  text-align: justify;
}

.copyrightsection {
  text-align: center;
}

.sidedescarea .carerrlogo {
  padding: 15px 40px;
}

.refreshbtn {
  cursor: pointer !important;
  font-size: 20px;
}

.app-layout-simple .secondary-header-light .text-2xl {
  padding: 0 5px !important;
  font-size: 12px;
}

.app-layout-simple .secondary-header-light .menu-item {
  column-gap: 0 !important;
  padding: 0 2px !important;
  font-size: 12.5px;
}

.portalicon {
  margin-top: 5px;
}

.portal_Placement svg {
  margin-top: 8px !important;
}

.portal_Hostel svg {
  margin-top: 8px !important;
}

.rolenamecls {
  font-size: 12px;
}

.dropdownrolenamecls {
  color: #000;
}

.cancelcustombtn {
  border-radius: 7px;
  width: 75px;
  background-color: #ff0000;
  color: #fff;
  cursor: pointer;
}

.cancelcustombtn span {
  position: relative;
  top: 7px;
  left: 3px;
}

.StudentWiseAverageAttendanceReportcls .datatablearea table td {
  text-align: center;
}

.StudentWiseAverageAttendanceReportcls .datatablearea table td button {
  background: #4f46e5;
  color: #fff;
}

.StudentWiseAverageAttendanceReportcls .datatablearea table td button:hover {
  background: #4f46e5;
  color: #fff;
}

.LecturePlanReport .card-body {
  padding: 10px !important;
}

.LecturePlanReport .form-container .form-item {
  margin-bottom: 5px !important;
}

.LecturePlanReport table thead tr th:nth-last-child(1) {
  background: #006a94 !important;
  color: #fff;
}

.LecturePlanReport table thead tr th:nth-last-child(2) {
  background: #006a94 !important;
  color: #fff;
}

.LecturePlanReport table thead tr th:nth-last-child(3) {
  background: #006a94 !important;
  color: #fff;
}

.lectureEntryInputTablecls div {
  max-height: 450px !important;
}

.lectureEntryInputTablecls thead {
  z-index: 9;
}

.lectureEntryInputTablecls .tablebuttoncls {
  background: #4f46e5 !important;
  color: #fff !important;
}

.tablebuttoncls {
  background: #4f46e5 !important;
  color: #fff !important;
}

.lectureEntryInputTablecls td input {
  border: 1px solid #e5e7eb;
}

.lectureEntryInputTablecls tbody tr {
  text-align: center;
}

.lectureEntryInputTablecls table tbody tr td:nth-last-child(1) {
  width: 230px;
}

.lectureEntryInputTablecls table tbody tr td:nth-last-child(3) {
  width: 190px;
}

.lectureEntryInputTablecls table tbody tr td:nth-last-child(4) {
  width: 200px;
}

.lectureEntryInputTablecls table tbody tr td:nth-last-child(6) {
  width: 200px;
}

.lectureEntryInputTablecls.tutorialCls table tbody tr td:nth-last-child(2) {
  width: 230px !important;
}

.BatchWisePaperList table thead tr th {
  text-align: center;
}

.BatchWisePaperList table tbody tr {
  text-align: center;
}

.studentBatchTableCls .card-body {
  height: 300px !important;
}

.studentBatchTableCls .card-body div {
  overflow-y: scroll;
  height: 270px !important;
  width: 100%;
}

.studentBatchTableCls .card-body .unassigned-students table tr th {
  color: rgb(255, 255, 255) !important;
}

.studentBatchTableCls table thead .headercheckbox {
  border: 1px solid #969494;
}

.studentBatchTableCls table thead .headercheckbox:checked {
  background-color: transparent;
  border: 1px solid;
}

.studentGroupTableCls .card-body {
  height: 440px !important;
}

.studentGroupTableCls .card-body .students-list {
  overflow-y: scroll;
  height: 326px !important;
  width: 100%;
}

.studentGroupTableCls .card-body .students-list table tr th {
  color: rgb(255, 255, 255) !important;
}

.studentGroupTableCls table thead .headercheckbox {
  border: 1px solid #969494;
}

.studentGroupTableCls table thead .headercheckbox:checked {
  background-color: transparent;
  border: 1px solid;
}

.studentGroupTableCls table thead th:first-child {
  text-align: center;
}

.studentGroupTableCls table tbody td:first-child {
  text-align: center;
}

.studentGroupTableCls .table-compact .thead > .tr > .th,
.table-compact tbody > tr > td,
.table-compact thead > tr > th {
  padding: 5px 0.75rem !important;
}

.AcademicsMasterAddMenu .card-body .form-item {
  margin-bottom: 0px !important;
}

.commonreporttableforsummery tbody tr td {
  text-align: center;
}

.commonreporttableforsummery thead tr th {
  text-align: center;
}

.universityAttendanceListClass table thead tr th:nth-last-child(1) {
  background: #006a94 !important;
  color: #fff;
}

.AttnAnalysisChartClass {
  margin-top: 11px;
}

.AttnAnalysisChartClass .card-body {
  padding: 5px;
}

.AttnAnalysisChartClass .card-body canvas {
  height: 250px !important;
  width: 250px !important;
  margin-left: 40px !important;
}

.AttnAnalysisChartClass h6 {
  text-align: center;
}

.attendanceAnalysisTable .card-body {
  height: 285px !important;
}

.attendancelinechart .card-body {
  padding: 5px;
}

.attendancelinechart .card-body canvas {
  padding: 5px;
}

.attendancelinechart h6 {
  text-align: center;
}

.datatablearea .tablebutton button {
  background: #4f46e5;
  color: #fff;
}

.datatablearea .tablebuttoncls {
  background: #4f46e5 !important;
  color: #fff !important;
}

.threeDotLoader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -24px 0 #4f46e5, 24px 0 #fff;
  }

  66% {
    background: #4f46e5;
    box-shadow: -24px 0 #fff, 24px 0 #fff;
  }

  100% {
    background: #fff;
    box-shadow: -24px 0 #fff, 24px 0 #4f46e5;
  }
}

.departmentwisetableview .datatablearea table thead tr th {
  text-align: center;
}

.departmentwisetableview .datatablearea table thead tr th:nth-last-child(2) {
  width: 334px;
}

.levelThreeAttendanceReportcls .datatablearea .card-body {
  padding: 10px !important;
}

.deptAttendanceTable .datatablearea tbody tr {
  background: #00d4b5 !important;
  color: #000;
}

.deptAttendanceTable .datatablearea tbody tr:first-child {
  background: #e7bd01 !important;
  color: #000;
}

.electivepaperlist .datatablearea table {
  text-align: center;
}

.electivepaperlist .datatablearea table tbody hr {
  background-color: #9f9f9f;
  height: 1px;
  border: 0;
}

.datatablearea table tbody .lineforbreak {
  background-color: #9f9f9f;
  height: 1px;
  border: 0;
}

.datatablearea .psychology-table {
  height: 280px;
  overflow-y: scroll;
}

.datatablearea .psychology-table table tbody hr {
  background-color: #9f9f9f;
  height: 1px;
  border: 0;
  margin-top: 10px !important;
}

.datatablearea .psychology-table table tbody td {
  padding: 0px !important;
  text-align: center !important;
}

.datatablearea .psychology-table table tbody .namecls {
  padding: 5px !important;
}

.datatablearea .psychology-table table tbody .multirow div {
  margin: 10px 0px !important;
  height: 35px;
  overflow: hidden;
  text-align: center;
  padding: 10px 0px;
}

.datatablearea .psychology-table .cancelbtn {
  background-color: #ff0000;
  color: #fff;
}

.datatablearea .psychology-table thead {
  z-index: 9 !important;
}

.paperWiseTopicTable table tbody td {
  padding: 0px;
}

.programme-wise-elective-paper
  .datatablearea
  table
  tbody
  tr
  td:nth-last-child(1) {
  padding: 0px !important;
}

.programme-wise-elective-paper
  .datatablearea
  table
  tbody
  tr
  td:nth-last-child(2) {
  padding: 0px !important;
}

.electivepaperlist .datatablearea table tbody tr td:nth-last-child(5) {
  text-align: left;
}

.electivepaperlist
  .datatablearea
  .table-default
  tbody
  tr
  td
  .elective-course-row {
  width: 300px !important;
  text-align: left !important;
}

.electivepaperlist
  .datatablearea
  .table-default
  tbody
  tr
  td
  .elective-course-row
  div {
  margin-bottom: 3px !important;
}

.studentfeedbackarea .datatablearea thead tr th:nth-last-child(1) {
  background: #00d4b5 !important;
}

.studentfeedbackarea
  .datatablearea
  thead
  tr:nth-last-child(1)
  th:nth-last-child(1) {
  background: #00d4b5 !important;
}

.studentfeedbackarea
  .datatablearea
  thead
  tr:nth-last-child(1)
  th:nth-last-child(2) {
  background: #00d4b5 !important;
}

.studentfeedbackarea
  .datatablearea
  thead
  tr:nth-last-child(1)
  th:nth-last-child(3) {
  background: #00d4b5 !important;
}

.studentfeedbackarea
  .datatablearea
  thead
  tr:nth-last-child(1)
  th:nth-last-child(4) {
  background: #00d4b5 !important;
}

.dualbuttonarea {
  display: flex;
}

.dualbuttonarea button {
  width: 60px;
}

.feedbackcolorarea {
  text-align: center;
  display: flex;
  justify-content: center;
}

.feedbackcolorarea div {
  padding: 5px;
}

.ckeditor .ck-editor__editable {
  height: 200px !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(2) th:nth-last-child(1) {
  background: rgb(190, 131, 2) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(1) {
  background: rgb(190, 131, 2) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(2) {
  background: rgb(190, 131, 2) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(3) {
  background: rgb(190, 131, 2) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(4) {
  background: rgb(190, 131, 2) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(2) th:nth-last-child(2) {
  background: rgb(10, 163, 112) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(5) {
  background: rgb(10, 163, 112) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(6) {
  background: rgb(10, 163, 112) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(7) {
  background: rgb(10, 163, 112) !important;
}

.AttnAnalysisTableClass thead tr:nth-last-child(1) th:nth-last-child(8) {
  background: rgb(10, 163, 112) !important;
}

.radiobtnarea {
  margin-top: 10px;
}

.searchorarea {
  display: flex;
  justify-content: center;
  margin-top: 38px;
}

.searchorarea div {
  border-radius: 6px;
  cursor: pointer;
  background: #4f46e5;
  color: #fff;
  height: 24px;
  padding: 1px;
  box-shadow: 5px 5px 5px #bdbdbd;
}

.searchorarea .ortxt {
  padding: 5px;
}

.teacherleavingstatusentry table tbody tr td:nth-last-child(1) {
  text-align: center;
}

.teacherleavingstatusentry table thead tr th:nth-last-child(2) {
  width: 370px;
}

.addactivitybtn {
  position: absolute;
  top: 107px;
  right: 15px;
}

.closecls {
  color: red;
}

.closebtncls {
  margin-left: 5px;
  background-color: #4f46e5;
  color: #fff;
}

.closebtncls:hover {
  margin-left: 5px;
  background-color: #5a5a5a;
  color: #fff;
}

.colbtncls {
  background-color: #4f46e5;
  color: #fff;
}

.colbtncls:hover {
  background-color: #5a5a5a !important;
  color: #fff !important;
}

.rolepermissionform .menulistarea .menuperitem {
  word-break: break-all;
  margin-right: 5px;
  margin-bottom: 5px;
}

.rolepermissionform .menulistarea .menuperitem p {
  font-size: 12px;
}

.rolepermissionform .menulistarea .menuperitem input {
  border: 1px solid #545454;
}

.rolepermissionform .menulistdiv {
  height: 46vh;
}

.rolepermissionform .searchinput input {
  width: 60%;
  float: right;
}

.viewpassword i {
  position: absolute;
  right: 10px;
  top: 40px;
  font-size: 20px;
  cursor: pointer;
}

.GeneratePasswordBtn {
  position: absolute;
  top: 4px;
  right: 2px;
  cursor: pointer;
  background: cyan;
  border-radius: 5px;
  font-size: 12px;
}

.UniversityMentoringReportTable table tbody tr td {
  text-align: center;
}

.UniversityMentoringReportTable table tfoot tr td {
  text-align: center;
}

.datatablearea table tfoot tr td {
  text-align: center;
}

.viewpopupbylink {
  text-decoration: underline;
  color: #4f46e5;
  cursor: pointer;
}

.viewpopupbtn {
  background-color: #4f46e5;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.viewpopupbtn:hover {
  background-color: #4f46e5 !important;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.teacher-activity-tab .teacher-table table {
  border: 1px solid #b4b4b4 !important;
}

.teacher-activity-tab .teacher-table table td {
  color: #000;
}

.TeacherInputTableCls table {
  min-width: 98%;
}

.departmental-list .tablearea {
  max-height: 327px !important;
}

.TeacherActivityInputCls .form-section {
  height: 385px !important;
}

.TeacherActivityInputCls .bottom-button-section .nextbtn {
  background: rgb(190, 131, 2) !important;
}

.TeacherActivityInputCls .bottom-button-section .nextbtn:hover {
  background: rgb(224, 160, 22) !important;
}

.TeacherActivityInputCls .bottom-button-section-last {
  width: 65%;
}

.TeacherInputTableCls .teacher-table {
  height: 405px;
  overflow-y: scroll;
}

.TeacherInputTableCls .tablearea thead tr th {
  border: none !important;
}

.TeacherInputTableCls .tablearea tbody tr td {
  border: none !important;
  padding: 5px;
  background-color: unset;
}

.TeacherInputTableCls .tablearea tbody .inputcls {
  display: inline-flex;
}

.TeacherInputTableCls .tablearea tbody .selectcls {
  display: inline-flex;
}

.TeacherInputTableCls .tablearea tbody .inputcls input {
  height: 28px !important;
}

.TeacherInputTableCls .tablearea tbody .dropdowncls {
  display: inline-flex;
}

.TeacherInputTableCls .tablearea tbody .dropdowncls .labelcls {
  margin-top: 0px;
  margin-right: 5px;
}

.TeacherInputTableCls .tablearea tbody .dropdowncls .select {
  width: 174px !important;
}

.TeacherInputTableCls .tablearea tbody .inputcls span {
  margin-top: 8px;
  margin-right: 5px;
  font-size: 11px;
  width: 46px;
}

.TeacherInputTableCls .tablearea tbody .selectcls span {
  margin-right: 5px;
  width: 50px;
  font-size: 11px;
}

.TeacherInputTableCls .tablearea tbody .checkboxcls {
  width: 10px;
  text-align: center;
}

.TeacherInputTableCls .tablearea tbody .activitycls {
  width: 200px;
  font-size: 11px;
}

.TeacherInputTableCls .tablearea tbody .selectcls .select .select__control {
  height: 35px !important;
  width: 235px;
}

.TeacherInputTableCls .tablearea tbody tr:nth-child(odd) {
  background-color: #e2e2e2;
}

.TeacherInputTableCls .tablearea tbody tr:nth-child(odd):hover {
  background-color: #e2e2e2;
}

.TeacherInputTableCls .tablearea tbody tr:nth-child(even) {
  background-color: #fff;
}

.TeacherInputTableCls .tablearea tbody tr:nth-child(even):hover {
  background-color: #fff;
}

.TeacherInputTableCls .tablearea tbody .checkboxcls input {
  border: 1px solid #969494;
}

.DepartmentInputTable tr td .upload-file {
  width: 112px;
}

.DepartmentInputTable tr td .upload-file .upload-file-info {
  display: none;
}

.TeacherInputTableCls table td {
  color: #000;
}

.DepartmentInputTable .tablearea tbody .filecls {
  display: inline-flex;
  align-items: center;
  min-height: 70px;
}

.DepartmentInputTable .tablearea tbody .filecls span {
  margin-right: 5px;
  width: 75px;
}

.DepartmentInputTable .tablearea tbody .filecls .upload {
  width: 11rem !important;
}

.DepartmentInputTable .tablearea tbody .filecls .upload button {
  height: 35px;
  /* width: 165px; */
  margin-right: 5px;
  width: 100%;
}

.DepartmentInputTable .tablearea tbody .inputcls input {
  height: 35px;
  /* width: 305px !important; */
  width: 100%;
}

.DepartmentInputTable .tablearea tbody .filecls .upload-file-list {
  margin-left: 5px;
}

.DepartmentInputTable .tablearea tbody .selectcls .select {
  /* width: 305px !important; */
  width: 100%;
}

.DepartmentInputTable .tablearea tbody .selectcls .select .select__control {
  height: 35px;
  width: 185px !important;
  margin-left: 6px;
}

.DepartmentInputTable .tablearea tbody .selectcls span {
  width: 75px;
}

.DepartmentInputTable table td {
  color: #000;
}

.datatablearea .linkclass {
  text-decoration: underline;
  color: #4f46e5;
  cursor: pointer;
}

.MentoringAttendance tfoot {
  display: none;
}

.MentoringProgressReport table tfoot tr:nth-last-child(1) {
  font-weight: 700;
  background-color: rgb(252, 252, 75);
  color: #000;
}

.MentoringProgressReport table tfoot tr:nth-last-child(2) {
  font-weight: 700;
  background-color: rgb(248, 55, 55);
  color: #000;
}

.MentoringProgressReport table tfoot tr:nth-last-child(3) {
  font-weight: 700;
  background-color: #64da64;
  color: #000;
}

.studentSelfImg img {
  height: 255px !important;
  width: 100% !important;
  object-fit: cover !important;
}

.CenterReportSchoolWiseFeedbackScore .datatablearea .Average_Score {
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.FeedbackAnalysisSchoolWiseReport {
  margin-left: 10px;
}

.FeedbackAnalysisSchoolWiseReport.card-border {
  border-width: 0px;
}

.FeedbackAnalysisSchoolWiseReport .card-header-border {
  border-bottom-width: 0px;
}

.CenterReportAllDepartmentWiseFeedbackAnalysis {
  margin-left: 10px;
}

.CenterReportAllDepartmentWiseFeedbackAnalysis.card-border {
  border-width: 0px;
}

.CenterReportAllDepartmentWiseFeedbackAnalysis .card-header-border {
  border-bottom-width: 0px;
}

.CenterReportAllDepartmentWiseFeedbackAnalysis
  .chartrestreport
  div:nth-last-child(11) {
  margin-top: 150px;
}

.CenterReportAllDepartmentWiseFeedbackAnalysis
  .chartrestreport
  div:nth-last-child(10) {
  margin-top: 150px;
}

.CenterReportAllDepartmentWiseFeedbackAnalysis
  .chartrestreport
  div:nth-last-child(9) {
  margin-top: 150px;
}

.stickynote i {
  font-size: 22px;
  color: #4f46e5;
  cursor: pointer;
  float: right;
  margin-top: 5px;
}

.lockunlockcls:disabled {
  background-color: #988de5 !important;
}

.useracareaportal {
  display: flex;
  background: #4f46e5;
  justify-content: space-between;
  margin: -10px -10px 10px;
  color: #fff;
  padding: 0px 10px;
  height: 3.5rem;
}

.useracareaportal .dark .header-action-item-hoverable:hover,
.header-action-item-hoverable:hover:hover {
  color: #fff;
}

.useracareaportal .logoimg img {
  width: 130px;
  padding-top: 3px !important;
}

.rolepermissiontable .datatablearea tbody tr td:first-child {
  width: 150px;
  word-break: break-all;
}

.BatchWiseFilterForm .select .select__control {
  min-width: 198px;
}

.TeacherPaperTaggingTable table tbody tr td:nth-last-child(1) {
  width: 120px;
}

.TeacherPaperTaggingTable table tbody tr td:nth-last-child(3) {
  width: 80px;
}

.TeacherPaperTaggingTable table tbody tr td:nth-last-child(2) {
  text-align: left;
  padding: 5px;
  width: 500px;
}

.TeacherPaperTaggingTable table tbody tr td:nth-last-child(4) {
  width: 100px;
  text-align: left;
  padding: 5px;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.MLPStatusReport table tbody tr td:nth-last-child(1) {
  text-align: center;
}

.lectureplanbtns {
  display: flex;
  justify-content: center;
}

.lectureplanbtns .tablebuttoncls {
  height: 35px;
  width: 35px;
  margin-right: 5px;
}

.LectureEntryInputTableCustom table td .form-container .form-item {
  margin: 5px 5px 5px 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LectureEntryInputTableCustom table td .form-container .select-md {
  width: 150px;
}

.LectureEntryInputTableCustom table td .form-container .input-md {
  width: 150px;
}

.LectureEntryInputTableCustom .editbtn {
  margin-left: 0px !important;
  background: #4f46e5;
  color: #fff !important;
}

.LectureEntryInputTableCustom .editbtn:hover {
  margin-left: 0px !important;
  background: #6a63f5 !important;
  color: #fff !important;
}

.LectureEntryInputTableCustom .editbtn i {
  color: #fff !important;
  font-size: inherit;
  font: normal normal normal 14px/1 FontAwesome;
}

.LectureEntryInputTableCustom .studentMentoringtable {
  overflow: auto;
  max-height: 480px;
  width: 100%;
}

.LectureEntryInputTableCustom .studentMentoringtable table {
  /* position: absolute;
    z-index: 9;
    width: 97%;
    overflow: auto;
    max-height: 480px; */
}

.LectureEntryInputTableCustom .studentMentoringtable table thead {
  z-index: 9 !important;
}

.nodatafound {
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin-top: 50px;
}

.LectureEntryInputTableCustom
  .studentMentoringtable
  table
  td:nth-last-child(1) {
  width: 80px;
}

/* .LectureEntryInputTableCustom .studentMentoringtable .Select-menu-outer {
    top: auto;
    bottom: 100%;
} */

.LectureEntryTutorialInputTable table td .schedule_faculty .select-md {
  width: 100% !important;
}

.dashboardcardlistarea .card-body h3 {
  text-transform: uppercase;
}

.anglebtncls {
  display: inline-grid;
  justify-content: center;
  margin-top: 15px;
}

.anglebtncls button {
  margin-bottom: 10px;
  width: 140px;
}

.coursepapersection {
  min-height: 300px !important;
}

.coursepapersection .stdlistarea {
  height: 280px !important;
}

.coursepaperbtn {
  justify-content: center;
}

.coursepapersection .papercardleft .cardtoparea {
  display: flex;
  justify-content: space-between;
}

.coursepapersection .papercardleft .cardtoparea .flexboxselectarea input {
  border: 1px solid #4f46e5;
  border-radius: 5px;
  padding: 3px 6px;
  width: 280px;
}

.coursepapersection .papercardleft .cardtoparea .flexboxselectarea input:focus {
  outline: none !important;
  border: 1px solid #4f46e5;
}

.coursepapersection .papercardright .cardtoparea {
  padding: 3px;
}

.menu-item::-webkit-scrollbar {
  border-radius: 19px;
  height: 3px !important;
}

/* HTML: <div class="loader"></div> */
.customloader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  margin-top: 50px;
}

.customloader::before,
.customloader::after {
  content: "";
  grid-area: 1/1;
  --c: no-repeat radial-gradient(farthest-side, #25b09b 92%, #0000);
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  animation: l12 1s infinite;
}

.customloader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear;
}

@keyframes l12 {
  100% {
    transform: rotate(0.5turn);
  }
}

.teacherWiseStudentAttendanceForm {
  padding: 0px !important;
}

.topicpaperactions .editcls {
  font-size: 15px;
  margin-right: 10px;
  color: #4f46e5;
  cursor: pointer;
}

.topicpaperactions .deletecls {
  font-size: 15px;
  color: #ff0000;
  cursor: pointer;
}

.paperWiseTopicForm {
  height: 46vh;
}

.commonmodaltable {
  height: 36vh;
}

.commonmodaltable table thead {
  z-index: 9;
}

.topicpaperactions .resetbtn {
  background: #ff0000;
}

.academicsMasterCourseFormPage form {
  min-height: 70vh;
}

.academicsMasterCourseFormPage .progressloader {
  height: 10px;
}

.paperWiseTopicTable tbody {
  text-align: center !important;
}

.paperWiseTopicCopycls {
  min-height: 435px;
  overflow-y: scroll;
}

.paperWiseTopicForm thead {
  z-index: 9;
}

.dashboardConfigForm {
  min-height: 455px;
}

.commonpageareaformodal {
  min-height: 465px;
  overflow-y: scroll;
  padding-bottom: 10px;
}

.feestrpage {
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.feestrpage span {
  font-weight: 600;
}

.feestructureaddmore .heading {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
}

.tablesearchbutton {
  display: flex-start;
  justify-content: flex-end;
}

.searchresetbtn {
  background: #006a94 !important;
}

.searchresetbtn:hover {
  background: #028ec5 !important;
}

.googlecapture {
  display: flex;
  justify-content: center;
}

.noticesection .leftside {
  max-height: 335px;
  overflow-y: scroll;
}

/* .noticesection .righside {
    max-height: 298px;
    overflow-y: scroll;
} */

.noticesection .card-body {
  padding: 0px !important;
}

.noticesection .noticebody {
  padding: 10px;
}

.noticesection .righside .noticetitle {
  text-align: left;
  background: #3b82f680;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.noticesection .righside .noticetitle h5 {
  margin-left: 5px;
  margin-right: 5px;
}

.noticesection .replybtn {
  margin-left: 0px !important;
  background: #4f46e5;
  color: #fff !important;
  width: 35px;
}

.noticesection .replybtn:hover {
  background: rgb(99 102 241 / var(--tw-bg-opacity));
}

.noticesection .editbtn {
  margin-left: 0px !important;
  background: #4f46e5;
  color: #fff !important;
  width: 35px;
}

.noticesection .editbtn:hover {
  background: rgb(99 102 241 / var(--tw-bg-opacity));
}

.noticesection .msgsendbtn {
  margin-left: 0px !important;
  background: #4f46e5;
  color: #fff !important;
}

.noticesection .msgsendbtn:hover {
  background: rgb(99 102 241 / var(--tw-bg-opacity));
}

.noticesection .resetbtn {
  margin-left: 0px !important;
  background: #ff0000;
  color: #fff !important;
}

.noticesection .resetbtn:hover {
  background: #fb6969;
}

.noticesection .deletebtn {
  margin-left: 0px !important;
  background: #ff0000;
  color: #fff !important;
  width: 35px;
}

.noticesection .deletebtn:hover {
  background: #fb6969;
}

.noticesection .noticebuttonarea {
  margin-top: 12px;
}

.logincapture .capturearea .capcode {
  text-align: center;
  /* padding: 25px; */
  font-size: 23px;
  font-style: italic;
  letter-spacing: 5px;
  background: #dddeff;
  width: 100%;
  height: 40px;
  margin-top: 25px;
}

.logincapture .capturearea .capcode div {
  margin: 2px 0px;
}

.logincapture .capturearea .reloadicon {
  text-align: center;
  padding: 25px;
  font-size: 23px;
  font-style: italic;
  letter-spacing: 3px;
}

.logincapture .capturearea .reloadicon i {
  font-size: 20px;
  cursor: pointer;
}

.signinform .form-container .form-item {
  margin-bottom: 20px !important;
}

#capcode {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.noticecategorylist {
  margin-top: 0px;
}

.noticecategorylist table {
  font-size: 12px !important;
  text-align: center;
  font-weight: 600;
  color: #000;
  min-width: 100%;
  table-layout: auto;
}

.noticecategorylist table thead tr th {
  color: #fff !important;
  text-align: center;
  font-size: 11px !important;
}

.noticecategorylist table tbody tr {
  font-size: 12px !important;
}

.noticecategorylist .noticebadge {
  position: relative;
  top: -6px;
}

.noticecategorylist .noticecatname {
  color: #4f46e5 !important;
  font-size: 14px !important;
  padding: 2px 5px !important;
  font-weight: 700;
}

.noticecategorylist .categorylink {
  text-decoration: underline;
  cursor: pointer;
}

.noticecategorylist .card-body {
  padding: 10px;
}

.noticecategorylist table tbody tr :nth-last-child(3) {
  text-align: left;
}

.noticecategorylist table tbody tr :nth-last-child(2) {
  text-align: center;
  padding-left: 5px !important;
}

.noticecategorylist .noticecatname.inactive {
  color: #766ffb !important;
  font-weight: 500 !important;
}

.datatablearea .grandtotalcls {
  font-weight: 600;
  font-size: 14px;
}

.targetaudience .maintext {
  font-size: 12px;
}

.targetaudience .volumeUp {
  font-size: 30px !important;
  margin-right: 5px;
}

.targetaudience .fieldname {
  font-size: 12px;
  font-weight: 650;
  color: #000;
}

.assignPaperGroupTable .status .minuscircle {
  font-size: 25px;
  color: #eb0202;
}

.assignPaperGroupTable .status .checkcircle {
  font-size: 25px;
  color: #2feb00;
}

.assignPaperGroupTable .actionbutton {
  margin: 5px;
}

.progressbararea {
  height: 15px;
}

.assignPaperGroupTable .dataexportbtn {
  height: 30px;
}

.assignPaperGroupTable {
  max-height: 410px;
  overflow-y: scroll;
}

.assignPaperGroupTable thead {
  z-index: 9 !important;
}

.groupTransferTable {
  max-height: 410px;
  overflow-y: scroll;
}

.groupTransferTable thead {
  z-index: 9 !important;
}

.groupTransferTable .dataexportbtn {
  height: 30px;
}

.commontableforpdf table tbody tr td {
  line-height: 25px;
}

.signuppagetitle {
  font-size: 20px;
  font-weight: 600;
}

.groupTransferTable .headercheckbox .checkbox:checked {
  background-color: transparent;
  border: 1px solid;
}

.groupTransferTable .selectgroup {
  width: 130px;
}

.groupTransferTable .groupno {
  width: 75px;
}

.versionchangedialog h5 {
  font-size: 16px !important;
}

.versionchangedialog .close-btn-default {
  display: none;
}

.noticelist table {
  font-size: 12px !important;
  text-align: center;
  font-weight: 600 !important;
  color: #000 !important;
  min-width: 100%;
  table-layout: auto;
}

.noticelist thead tr th {
  color: #fff !important;
}

.noticelist .noticecatname {
  color: #4f46e5 !important;
  font-size: 14px !important;
  padding: 2px 5px !important;
  font-weight: 700;
}

.noticelist .card-body {
  padding: 10px;
}

.noticelist table tbody tr :nth-last-child(5) {
  text-align: left !important;
}

.noticelist .categorylink {
  text-decoration: underline;
  cursor: pointer;
}

.noticelist .noticecatname.inactive {
  color: #766ffb !important;
  font-weight: 500 !important;
}

.noticelist table tbody .fa-star {
  color: #ffc422 !important;
  cursor: pointer;
}

.noticelist table tbody .fa-star-o {
  cursor: pointer;
}

.smsattendance .smstextarea {
  /* height: 235px; */
}

.smsattendance .ortext {
  display: flex;
  justify-content: center;
}

.smsattendance .ortext div {
  width: 30px;
  border-radius: 6px;
  cursor: pointer;
  background: #4f46e5;
  color: #fff;
  height: 24px;
  padding: 1px;
  box-shadow: 5px 5px 5px #bdbdbd;
}

.smsattendance .ortext span {
  padding: 6px;
}

.smsattendance .target-audience-sms {
  min-height: 75px;
}

.smsattendance .select-criteria-text {
  min-height: 20px;
}

.smsattendance .select-criteria-text div {
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #00a189;
  text-decoration: underline;
}

.smsattendance .status .minuscircle {
  font-size: 25px;
  color: #eb0202;
}

.smsattendance .status .checkcircle {
  font-size: 25px;
  color: #2feb00;
}

.mobile-logo {
  display: flex;
  justify-content: center;
  line-height: 25px;
  margin-bottom: 20px;
}

.stidentinfo .semester-attendance {
  overflow: auto;
  max-height: 500px;
  width: 97%;
}

.activitysavebtn {
  background-color: #006a94;
}

.activitysavebtn:hover {
  background-color: #057faf !important;
}

.current-password .form-section .form-item {
  margin-bottom: 5px;
}

.current-password .note-text {
  font-size: 14px;
  font-weight: 490;
  color: #a50000;
}

.view-mlp-pdf .form-section .form-item {
  margin-bottom: 5px;
}

.view-mlp-pdf .form-section {
  height: 200px;
}

.student-batch-entry .form-section .form-item {
  margin-bottom: 5px !important;
}

.student-batch-entry .loader-area {
  height: 10px;
}

.student-year-lack .card-body {
  padding: 2px 10px;
}

.student-year-lack .card-body .title {
  margin-bottom: 5px;
}

.student-group-entry .tabsection .tabs {
  display: flex;
  justify-content: center;
}

.student-group-entry .tabsection .tab-nav-pill {
  background: #74a8bd;
  color: #fff;
  width: 200px;
}

.student-group-entry .tabsection .tab-nav-active {
  background-color: #006a94;
}

.student-group-entry .tabsection .tab-nav-pill:hover {
  background-color: #006a94;
}

.student-group-entry .datatablearea table td .papercode {
  color: #0090c9 !important;
}

.assign-elective-paper .tabsection .tabs {
  display: flex;
  justify-content: center;
}

.assign-elective-paper .tabsection .tab-nav-pill {
  background: #74a8bd;
  color: #fff;
  width: 250px;
}

.assign-elective-paper .tabsection .tab-nav-active {
  background-color: #006a94;
}

.assign-elective-paper .tabsection .tab-nav-pill:hover {
  background-color: #006a94;
}

.assign-elective-paper .datatablearea table td .papercode {
  color: #0090c9 !important;
}

.assign-student-batch .tabsection .tabs {
  display: flex;
  justify-content: center;
}

.assign-student-batch .tabsection .tab-nav-pill {
  background: #74a8bd;
  color: #fff;
  width: 300px;
}

.assign-student-batch .tabsection .tab-nav-active {
  background-color: #006a94;
}

.assign-student-batch .tabsection .tab-nav-pill:hover {
  background-color: #006a94;
}

.assign-student-batch .datatablearea table td .papercode {
  color: #0090c9 !important;
}

.MLPStatusReport .tabsection .tabs {
  display: flex;
  justify-content: center;
}

.MLPStatusReport .tabsection .tab-nav-pill {
  background: #74a8bd;
  color: #fff;
  width: 200px;
}

.MLPStatusReport .tabsection .tab-nav-active {
  background-color: #006a94;
}

.MLPStatusReport .tabsection .tab-nav-pill:hover {
  background-color: #006a94;
}

.MLPStatusReport .datatablearea table td .papercode {
  color: #0090c9 !important;
}

.mlp-deactivate-list .datatablearea table {
  text-align: center;
}

.mlp-deactivate-list .datatablearea table tbody hr {
  background-color: #9f9f9f;
  height: 1px;
  border: 0;
}

.mlp-deactivate-list .datatablearea table tbody tr td:nth-last-child(2) {
  padding: 0px !important;
}

.mlp-deactivate-list .datatablearea table tbody tr td:nth-last-child(3) {
  padding: 0px !important;
}

.mlp-deactivate-list .datatablearea table tbody tr td:nth-last-child(4) {
  padding: 0px !important;
}

.student-batch-entry .datatablearea table tbody hr {
  background-color: #9f9f9f;
  height: 1px;
  border: 0;
}

.student-batch-entry .datatablearea table tbody tr td:nth-last-child(1) {
  padding: 0px !important;
}

.student-batch-entry .datatablearea table tbody tr td:nth-last-child(2) {
  padding: 0px !important;
}

.student-year-lack .card-body {
  height: 325px !important;
}

.student-year-lack .card-body .student-list {
  overflow-y: scroll;
  height: 280px !important;
  width: 100%;
}

.student-year-lack .card-body .batch-list {
  overflow-y: scroll;
  height: 280px !important;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.yearlag-entry-form .form-section .form-item {
  margin-bottom: 5px;
}

.yearlag-entry-form .note-text {
  font-size: 14px;
  font-weight: 490;
  color: #a50000;
}

.attendance-update-table .datatablearea td .plan-details-ids {
  text-align: left;
  margin-left: -3px;
  margin-right: -3px;
  width: 180px;
}

.attendance-update-table .datatablearea .btnhighlight {
  background-color: #2feb00;
}

.copy-permissions .form-section .form-item {
  margin-bottom: 5px;
}

.copy-permissions .form-section {
  min-height: 250px;
}

.datatablearea .table-default thead tr th.imfont {
  font-size: 16px !important;
}
.datatablearea thead.stz {
  z-index: 1 !important;
}
/* Loading screen for the section */
#loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Keep the loader above the content */
}

/* Spinner style */
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
.teacher-activity-tab .studentDetails {
  max-height: 50vh;
  overflow: scroll;
}
.bdb {
  border-bottom: 1px solid #ccc;
}
.slctstyl {
  width: 100%;
  height: 40px;
  border: 1px solid #c9c9c9;
  border-radius: 7px;
  text-align: center;
}

.slctstyl:focus-visible {
  border-color: #4f46e5;
  outline-color: #4f46e5;
}

.ppdt table td,
.ppdt tr,
.ppdt th,
.ppdt table {
  border: none !important;
}
/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ppacb:checked {
  background-color: #000;
}
.adminful li {
  display: flex;
}
.adminful i {
  color: #000;
  margin-right: 5px;
  margin-top: 7px;
  font-size: 7px;
}
@media only screen and (max-width: 575px) {
  .signuppagetitle {
    font-size: 16px;
    font-weight: 600;
  }

  .notesection {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .commonDashboardArea .dynamiccardarea {
    overflow-x: auto;
    overflow-y: auto;
  }

  .scheduleteacherbtnarea button {
    margin: 5px;
  }

  .dynamiccardarea {
    height: auto !important;
  }

  .modalpopup .drawer-content.horizontal {
    width: 86% !important;
  }

  .presentsection {
    min-height: 26vh;
  }

  .coursepapersection .papercardleft .cardtoparea .flexboxselectarea input {
    width: 180px !important;
  }

  .TeacherInputTableCls .tablearea tbody .selectcls .select .select__control {
    width: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .TeacherInputTableCls .tablearea tbody .selectcls .select .select__control {
    width: 110px;
  }

  .finalsavebtn {
    margin-right: 115px;
  }
}
