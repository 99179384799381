/* card gradient color  */
.cardbg_red_white {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, #fe7096, #ffbf96) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_red_white .fa-arrow-circle-right {
    color: #6e6e6e;
}

.cardbg_blue_white {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, #047edf, #90caf9 99%) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_blue_white .fa-arrow-circle-right {
    color: #6e6e6e;
}

.cardbg_cyan_white {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, #07cdae, #84d9d2) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_cyan_white .fa-arrow-circle-right {
    color: #6e6e6e;
}

.cardbg_cyan_white {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, #07cdae, #84d9d2) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_cyan_white .fa-arrow-circle-right {
    color: #6e6e6e;
}

.cardbg_violet_white {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, #9a55ff, #da8cff) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_violet_white .fa-arrow-circle-right {
    color: #6e6e6e;
}

.cardbg_yellow_white {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, #ffd500, #f6e384) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_yellow_white .fa-arrow-circle-right {
    color: #6e6e6e;
}

.cardbg_sky_purple {
    background-image: url('./assets/img/circle-bg.svg'), linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1)) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}

.cardbg_sky_purple .fa-arrow-circle-right {
    color: #6e6e6e;
}

.tablebg_gray_sky {
    background-image: url('./assets/img//circle-bg.svg'), linear-gradient(to right, #59326c38, #55b7ff75) !important;
    background-repeat: no-repeat !important;
    background-position: center right !important;
    border: 1px solid #bdbdbd !important;
    color: #FFF !important;
}
